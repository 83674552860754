import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`High-volume recruitment can be hard to get right and providing a personalized experience for every applicant is a lofty goal. Enter `}<a parentName="p" {...{
        "href": "https://talkpush.com/"
      }}>{`Talkpush`}</a>{`, a recruitment platform that leverages conversation and social media to connect companies with talent. Through their chatbot Stanley, companies are able to quickly screen a large volume of candidates for the right fit.`}</p>
    <p>{`“Talkpush built a system that allows recruiters to get a more holistic view of the candidates coming in from all their different channels and make decisions faster and more effectively,” said Christina Adamidou, Product Manager, Conversational Interfaces. “ We believe the future is conversational.”`}</p>
    <p>{`Talkpush has screened over one million candidates on their platform in English and Spanish through over 50 clients in 13 countries. Supported by `}<a parentName="p" {...{
        "href": "https://dialogflow.com/"
      }}>{`Dialogflow`}</a>{`, Talkpush’s chatbot provides an initial screening and can pass inquiries to a human, schedule interviews, and engage candidates while they’re waiting at an onsite interview. One of the most frustrating parts of the recruitment process for applicants is waiting to hear back, and Talkpush allows companies to bypass that experience by offering always-live communication channels that are instant and engaging.`}</p>
    <p>{`Initially, Stanley was built to recruit for call center workers in the business process outsourcing (BPO) sector , but Talkpush’s expansion is bringing them into various industries, such as retail and education. Since their clients are large-scale enterprises, they expect routine reports on the chatbot’s performance. For that, they turned to Dashbot.`}</p>
    <h3><strong parentName="h3">{`Finding the perfect tool`}</strong></h3>
    <p>{`In order to create their client reports, Talkpush was relying on Power BI to generate data visualizations, but they needed analytics at a deeper, conversational level. Projects were also getting bottlenecked because they had to rely on the engineering team to provide data. The Conversational Design team was unable to address client and user concerns because they had no way of measuring chatbot performance. Considering that they were unable to secure potential clients on a few occasions because they lacked visibility, finding the perfect tool was a priority.`}</p>
    <p>{`“We have so much data, but we didn’t have any way to mine the data we were looking at to find the most important points of the conversation,” said Adamidou.`}</p>
    <p>{`The first solution they tried was Chatbase, but Adamidou felt like she was not getting the most accurate metrics from it. She and her team also found that it did not fit into their workflow, so no one monitored their Chatbase reports. It was as if they did not have a tool in place at all. They also considered other analytics platforms like Mixpanel, but found it to be general and not a good fit for chatbots.`}</p>
    <p>{`In August 2018, Talkpush found Dashbot and embarked on a one month trial. Pleased with their initial experience, they officially brought Dashbot on as their first conversational analytics solution.`}</p>
    <h3><strong parentName="h3">{`Insights from the message level`}</strong></h3>
    <p>{`Creating detailed monthly reports filled with rich conversational data is a necessary function of a company who works with clients who aren’t satisfied with just seeing a graph. According to Adamidou, they want to see what contributed to each improvement in performance from the message level.`}</p>
    <p>{`“The metrics that my team uses are very different than marketing, for example,” she said. “I needed something that was tailored for chatbots specifically.”`}</p>
    <p>{`What metrics would those be? According to Conversational Analyst John Alfonso, he is more interested in the numbers that point to the performance and health of the chatbot. Traditional analytics only showed how many people were talking to Stanley bot and for how long. Using Dashbot’s `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/#sentiment"
      }}>{`Sentiment Analysis`}</a>{` feature, Alfonso is able to see from the message level whether users are responding positively or negatively to their experience in the chatbot.`}</p>
    <p>{`Knowing which conversations cause problems for their users saves the Conversational Design team from combing through every single transcript. Instead, they can easily pinpoint which conversations need their attention by doing a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/#search"
      }}>{`Transcript Search`}</a>{` for low sentiment scores. Having quick access and clear signage to issues that need optimization saves Alfonso a lot of time.`}</p>
    <p>{`“Sentiment is like our North Star that shows us if we’re going in the right direction or not,” said Adamidou.`}</p>
    <h3><strong parentName="h3">{`Pinpointing pivotal moments in conversations`}</strong></h3>
    <p>{`With Dashbot, Talkpush has access to actionable data. Analysts are able to see the completion rates for every account. Not only that, but if a user drops off before completion, they also have visibility to exactly what point each user chose not to continue. This information shows the team where in the conversation users are experiencing pain points. From there, Talkpush can optimize the conversation to remove those obstacles to completion.`}</p>
    <p>{`The feature that allows them to do this, `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/behavior/#goal-funnels"
      }}>{`Goal Funnels`}</a>{`, can also be utilized to track how well optimizations are performing. When Adamidou implements a change to the conversation, she relies on Dashbot to see if it was the right move. Goal Funnels allows her and the rest of the team to track improvements in the chatbot’s performance with each iteration. Dashbot provides a reliable way to test their hypotheses and moves along the optimization process with more data. It is also helpful in creating Talkpush’s internal monthly design metrics report that goes out to the whole company.`}</p>
    <p>{`According to Adamidou, having access to conversational metrics informs the chatbot design a lot. Talkpush is able to see which incoming messages trigger the fallback Intent through the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/tour/conversational-analytics/#unhandled"
      }}>{`Unhandled Report`}</a>{`. Stanley’s percentage of unhandled messages dropped drastically after they started to export the list of unhandled and mishandled messages to retrain their NLP, which meant their users receive a smoother experience.`}</p>
    <h3><strong parentName="h3">{`Doubling response accuracy for a more meaningful conversation`}</strong></h3>
    <p>{`Having access to Dashbot’s conversational analytics turned out to be a game changer to Talkpush. From January to July 2019, they doubled their chatbot response accuracy from 30 percent to 60 percent. Adamidou noted that it was a remarkable improvement for their clients in such a short period of time.`}</p>
    <p>{`With plans to expand Stanley’s capabilities, the Conversational Design team expects users to run into more problems, but they are more than prepared. With Dashbot’s reports, Talkpush is confident that they will be able to keep a close eye on Stanley’s performance and quickly solve any mishaps that may arise, creating a better experience for their users.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p">{`Dashbot is an analytics platform for conversational interfaces that enables enterprises to increase satisfaction, engagement, and conversions through actionable insights and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support DialogFlow, Alexa, Google Assistant, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      